.featuredRestaurantItem {
}

.discountListItem {
  color: #E52328;
}

.reviewItemLink {
  text-decoration: none;
}

.reviewItemLabel {
  margin: 0;
  vertical-align: text-top;
}

.reviewsPhotoThumbnail {
  --size: 120px;
  padding: 5px 10px 5px 0;
  cursor: pointer;
}

@media only screen and (max-width: 1440px) {
  .reviewsPhotoThumbnail {
    --size: 120px;
  }
}
@media only screen and (max-width: 900px) {
  .reviewsPhotoThumbnail {
    --size: 100px;
  }
}

@media only screen and (max-width: 426px) {
  .reviewsPhotoThumbnail {
    --size: 100px;
  }
}

