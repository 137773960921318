.confirmCodeCard {
  max-width: 400px;
  margin: 0 auto;
  padding: 30px;
  text-align: center;
}

.passwordlessHeader {
  height: 50px;
  padding: 5px 0 5px 15px;
  text-align: left;
  float: left;
}

@media only screen and (max-width: 991px) {
  .passwordlessHeader {
    padding: 5px 0 5px 10px;
  }
}

.spamText {
  max-width: 260px;
  text-align: center;
  margin: 0 auto;
}

.confirmCodeModal {
  --min-height: 100%;
  --min-width: 100%;
}

.confirmCodeCard a {
  text-decoration: none;
  color: #737373;
  margin: 0 auto;
}

.mailIcons {
  padding: 20px;
}

.mailImage {
  margin-right: 5px;
}

.outlookIcon {
  float: right;
}

@media only screen and (max-width: 400px) {
  .mailIcons {
    margin: 0px auto;
    padding: 30px 0;
  }
  .gmailIcon {
    margin: 0 auto;
    padding: 0;
  }
  .outlookIcon {
    float: none;
    padding: 0;
  }


}

@media only screen and (max-width: 410px) {
  .gmailIcon {
    padding-right: 9px;
  }
}

@media only screen and (max-width: 360px) {
  .gmailIcon {
    padding-right: 4px;
  }
}
