.dealPageCard {
  padding: 0 25px;
  height: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 0px;
}

.checkedInTitle {
  color: #E52328;
  margin-bottom: 5px !important;
  font-size: 2em !important;
}

.resetCheckInButton {
  margin-top: 30px;
}

.isAtRestaurantImage {
  margin: 0 auto;
  width: 150px;
}

.dealPageCardContent {
  text-align: center !important;
}

.dealPageIcon {
  margin-bottom: 10px;
}

.dealImage {
  max-width: 400px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.dealDeal {
  color: #E52328;
  font-size: 1.8em !important;
}

.dealPerk {
  color: #E52328;
  font-size: 1.3em !important;
}

.dealPageInstructions {
  font-size: 0.8em !important;
}

.dealPageDisclaimerContainer {
  margin-top: 10px;
}
.dealPageDisclaimers {
  font-size: 0.8em !important;
}

.dealDealCard {
  padding: 20px;
  border: 1px solid #E52328;
  box-shadow: 0px 3px 1px -2px #E52328;
}

@media only screen and (max-width: 600px) {
  .dealPageCard {
    margin-top: 0px;
  }

  .dealImage {
    margin-bottom: 10px;
  }

}
