.wpCreditsImage {
  width: 100px;
  height: 100px;
  margin: 0;
}

.wpCreditsCard {
  width: 300px;
  height: 100px;
  margin: 10px 20px 20px 0;
}

.reviewCredits {
  display: flex;
  padding: 0;
  margin-bottom: 40px;
}

.wpCreditsRow {
  justify-content: flex-start;
}

.wpCreditsCol {
  padding: 0;
}

.wpCreditsImageCol {
  padding: 0;
  margin: 0;
  max-width: 100px;
}

.wpCreditsHeader {
  padding: 5px 20px 10px 20px;
}

.wpCreditsTitle {
  font-size: 15px;
}

.wpCreditsContent {
  padding: 20px 20px 0px 20px;
}

.wpProfileButton {
  margin-left: 9px;
  text-transform: capitalize;
}
