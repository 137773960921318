.grecaptcha-badge {
  display: none;
}
.MuiIconButton-sizeMedium {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

/*.css-173nj1u-MuiButtonBase-root-MuiIconButton-root, .css-1t8wyba-MuiButtonBase-root-MuiIconButton-root {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.css-13go44a-MuiButtonBase-root-MuiIconButton-root, .css-1khl9uc-MuiButtonBase-root-MuiIconButton-root {
  margin-right: 100px !important;
  margin-left: 100px !important;
}*/

.PrivateDateTimePickerToolbar-penIcon {
  display: none !important;
}

.dateTimeFieldLabelIcon {
  font-size: 1.5em;
  margin-right: 10px;
  vertical-align: bottom;
  color: #E52328 !important;
 }

.dateTimeFieldLabelText {
  padding-bottom: 5px;
 }

.dateTimeModalCloseButton {
  margin: auto;
  width: 100px;
  margin-top: 40px;
}

.dateTimeComponent {
  margin: auto;
}

.dateTimeInputField {
  margin-top: 10px;
}

ion-popover {
  --offset-y: 0 !important;
}
.dateTimeModal {
}
.dateTimeModalContainer {
  padding: 0 60px;
  padding-bottom: 80px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (max-width: 700px) {
  .dateTimeModalContainer {
    padding: 0 30px;
  }
}



