.socialButtonsText {
  text-align: center;
}

.googleIcon {
  height: 20px;
  margin-right: 10px;
  width: 20px;
}

.googleLoginButton {
  margin-bottom: 10px;
}

.facebookIcon {
  height: 27px;
  margin-right: 10px;
  width: 30px;
}
