.thirtyDaysFree {
  color: #E52328;
}

.supportLocalArtistDetails {
  font-size: 0.65em;
  white-space: normal;
}
.supportLocalRestaurantDetails {
  font-size: 0.65em;
  white-space: normal;
}

.memberBenefitsDetails {
  font-size: 0.8em !important;
}

.membershipBenefits {
  padding: 10px 0;
}

.membershipsTitle h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.membershipsLoginTitle {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.membershipsLoginTitle p {
  font-size: 0.8em;
  text-align: center;
}

.membershipsLoginLink {
  font-size: 0.8em;
  text-align: center;
}

.membershipCard {
  max-width: 375px;
  margin: 20px auto;
  padding: 10px 20px;
  text-align: center;
}

.benefitsContainer {
  width: 200px;
  padding: 20px;
  margin: 0 auto;
  border: 1px grey solid;
}

@media only screen and (min-width: 600px) {
  .membershipsTitle h1 {
    margin-top: 40px;
    margin-bottom: 20px;
  }

}
