.facebookIcon {
  font-size: 25px;
}

.socialIcons {
  justify-content: flex-start;
  display: flex;
}

.creatorReviewsTitle {
  padding: 30px 0 0 18px;
}

.socialIcon {
  width: 40px !important;
}

.creatorProfileImage {
  width: 100% !important;
  height: auto !important;
  margin-bottom: 25px;
}

.creatorInfoCard {
}

.creatorBioCard {
  height: 100%;
  max-height: 400px;
  padding: 20px;
}

.contentCreatorPage {
  padding: 20px;
}

.contentCreatorContainer {
  display: flex;
  flex-wrap: wrap;
}

.creatorBioText p {
  line-height: 25px;
  font-size: 15px;
  padding: 20px 0px;
}

@media only screen and (min-width: 576px) {
  .creatorProfileImageCard {
    margin-right: 40px;
  }
}

@media only screen and (max-width: 1500px) {
  .creatorProfileImage {
    width: 80% !important;
  }
}

@media only screen and (max-width: 991px) {
  .creatorProfileImage {
    width: 70% !important;
  }
}

@media only screen and (max-width: 850px) {
  .creatorProfileImage {
    width: 90% !important;
  }
  .creatorBioCard {
    max-height: 500px;
  }
}

@media only screen and (max-width: 575px) {
  .creatorProfileImage {
    width: 50% !important;
  }
  .creatorBioCard {
    max-height: 550px;
    padding: 5px;
  }

  .creatorReviewsCard {
    padding: 0 !important;
  }

  .creatorReviewsContent {
    padding: 0 !important;
  }

}

@media only screen and (max-width: 475px) {
  .creatorProfileImage {
    width: 80% !important;
  }
  .creatorBioCard {
    max-height: 600px;
  }
}
@media only screen and (max-width: 400px) {
  .creatorProfileImage {
    width: 100% !important;
  }
  .creatorBioCard {
    height: 800px;
    max-height: 800px;
  }

}
