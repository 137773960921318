.topBannerAd {
  margin-left: 20px;
}

.amazonAdContainer {
  display: flex;
}

.amazonAd {
  margin-right: 30px;
}
.applicationPdfUpload {
  padding: 10px;
}
.applicationFormErrors {
  text-align: left;
  color: red;
}
.confirmNonPaidLabel {
  margin-bottom: 20px;
}
.applicationShieldLogo::part(image){
  height: 50px;
}

.applyRequiredText {
  text-align: left;
  padding: 20px 0 0 25px;
}

.positionOptionsTitle {
  padding: 30px 0 0 20px;
  text-align: left;
}

.applicationTextArea {
  min-height: 60px;
}

.applicationCard {
  max-width: 600px;
  margin: 30px auto;
  padding: 30px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .applicationCard {
    margin: 0;
    padding: 50px 0px;
  }
}

.loaderStyle{
  height: 9%;
  border-radius: 10px;
  font-weight: bold;
}

.confirmButtonStyle{
  border-radius: 10px !important;
    color: white !important;
    background-color: grey !important;
    border: 1px solid grey !important;
}
