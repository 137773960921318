.loginCard {
  max-width: 400px;
  margin: 0 auto;
  padding: 30px;
  text-align: center;
}
@media only screen and (max-width: 400px) {
  .loginCard {
    padding: 30px 0px;
  }
}

ion-content {
  --background: none;
}
ion-content {
  --background: none;
}

.loaderStyle{
  height: 9%;
  border-radius: 10px;
  font-weight: bold;
}

.confirmButtonStyle{
  border-radius: 10px !important;
    color: white !important;
    background-color: grey !important;
    border: 1px solid grey !important;
}

.leftButton{
  right: 46px !important;
}


.rightButton{
  right: 3px !important;
}
