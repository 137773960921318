.featuredRestaurantLabel {
  margin: 20px;
  color: #E52328;
}

.reviewsPageAd {
  padding: 5px 0 !important;
}

.reviewsPageAd .removeAdsButton {
  margin-left: 5px;
}

